import { addons } from "@storybook/addons";
import { create } from "@storybook/theming";

import brandImage from "./assets/logo.svg";

addons.setConfig({
  theme: create({
    brandTitle: "R3set.life Storybook",
    brandUrl: "https://www.r3set.life/",
    brandImage,
  }),
});